import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Form, Input, Modal, PageHeader, Row, Select, Table, Tabs, DatePicker, Pagination} from 'antd';
import {
    CalendarOutlined,
    CalendarTwoTone,
    CheckCircleTwoTone, FileExcelOutlined, FilePdfOutlined,
    HourglassTwoTone,
    PauseCircleTwoTone, PlusCircleOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';
import CareRequestForm from "./CareRequestForm";
import UserService from "./userService";
import CareRequest from "./CareRequest";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {useTranslation} from 'react-i18next';
import 'moment/locale/nl';
import {debounce} from "lodash"

moment.locale("nl");

const CareRequests = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [changeRecord, setChangeRecord] = useState(undefined);
    const [editRecord, setEditRecord] = useState(undefined);
    const [onHoldRecord, setOnHoldRecord] = useState(undefined);
    const [renewRecord, setRenewRecord] = useState(undefined);
    const [careRequestsActive, setCareRequestsActive] = useState([]);
    const [careRequestsHistory, setCareRequestsHistory] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateUntil, setDateUntil] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [showOnholdModal, setShowOnholdModal] = useState(false);
    const [selectedCareRequest, setSelectedCareRequest] = useState(undefined);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [numRequestsActive, setNumRequestsActive] = useState(0);
    const [paginationActive, setPaginationActive] = useState({
        pageSize: 10
    });
    const [downloading, setDownloading] = useState(false);
    const [medications, setMedications] = useState([]);
    const [selectedMedication, setSelectedMedication] = useState("");

    const handleChangeActive = (pagination, filters, sorter) => {
        getCareRequests(pagination.current)
    };

    const getCareRequests = async (page) => {
        if (page === undefined) {
            page = 1;
        }

        await setLoading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        const requestNumActive = await UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?count=1&active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&search=" + searchQuery + "&medication=" + selectedMedication + "&prescriptions=1", {
            method: "get"
        });
        const responseNumActive = await requestNumActive.json();
        await setNumRequestsActive(responseNumActive.numUvv);
        await setPaginationActive((pager) => {
            let newPager = pager;
            newPager.total = responseNumActive.numUvv;

            return newPager;
        });

        const request = await UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&page=" + page + "&search=" + searchQuery + "&medication=" + selectedMedication + "&prescriptions=1", {
            method: "get"
        });
        const response = await request.json();
        await setCareRequestsActive(response.items);

        if (!medications.length) {
            await setMedications(response.medication);
        }
        await setLoading(false);
    };

    useEffect(() => {
        getCareRequests(1);
    }, [dateFrom, dateUntil, searchQuery, selectedMedication]);

    const exportExcel = async () => {
        await setDownloading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        return UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&search=" + searchQuery + "&mode=download&prescriptions=1", {
            method: "GET",
        }).then(function(response){
            return response.blob();
        }).then(function(response){
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                const reader = new window.FileReader();
                reader.readAsDataURL(response);
                reader.onload = function() {
                    let fileName = "Yulp-Export";

                    let dataUrl = reader.result;
                    let element = document.createElement("a");
                    element.setAttribute("href", dataUrl);
                    element.setAttribute("download", fileName + ".xlsx");

                    element.style.display = "none";
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                };
            }
            else {
                alert("Uw browser ondersteund deze functionaliteit niet...");
            }

            setDownloading(false);
        });
    };

    const downloadPdf = async (record) => {
        const url = "/v1/client?uvvId=" + record.id + "&recept=1";

        return UserService.authFetch(url, {
            method: "GET",
        }).then(function(response){
            return response.blob();
        }).then(function(response){
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                const reader = new window.FileReader();
                reader.readAsDataURL(response);
                reader.onload = function() {
                    let fileName = "YULP_RECEPT_" + record.patientId;

                    let dataUrl = reader.result;
                    let element = document.createElement("a");
                    element.setAttribute("href", dataUrl);
                    element.setAttribute("download", fileName + ".pdf");

                    element.style.display = "none";
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                };
            }
            else {
                alert("Uw browser ondersteund deze functionaliteit niet...");
            }
        });
    };

    const columns = [
        {
            title: 'Patient ID',
            key: 'patientId',
            fixed: 'left',
            width: 250,
            visible: true,
            render: (index, record) => {
                if (record.rowType === "uvo") { // Afspraak, child row
                    return <span><CalendarOutlined /> {record.patientId}</span>
                }
                return (
                    <span>{record.patientId}</span>
                );
            }
        },
        {
            title: 'Referentie',
            fixed: 'left',
            width: 150,
            visible: true,
            dataIndex: 'ownReference',
            key: 'ownReference',
        },
        {
            title: "Aanmelddatum",
            dataIndex: 'dateReceived',
            key: 'dateReceived',
        },
        {
            title: t('name'),
            dataIndex: 'patientName',
            key: 'patientName',
        },
        {
            title: t('deliveryDate'),
            dataIndex: 'dateDelivery',
            key: 'dateDelivery',
        },
        {
            title: t('preferentProcedureDate'),
            key: 'dateAppointment',
            render: (index, record) => {
                let m = false;
                if (record.dateAppointment !== "Nog in te plannen") {
                    m = record.rowType === "uvv" ? moment(record.dateAppointment, "DD-MM-YYYY") : moment(record.dateAppointment, "DD-MM-YYYY HH:mm");
                }

                if (record.rowType === "uvv") {
                    return (
                        <>
                            <span>{record.rowType === "uvv" ? record.dateAppointment + " (voorkeur)" : t("waitingForAppointment")}</span>
                            <br />
                            <small>{m && m.fromNow()}</small>
                        </>
                    )
                }
                else {
                    if (record.dateAppointment === "Nog in te plannen") {
                        return "Nog in te plannen";
                    }
                    return (
                        <>
                            <span>{m.format("dd DD-MM-YYYY") + " om " + m.format("HH:mm") + "u"}</span>
                            <br />
                            <small>{m && m.fromNow()}</small>
                        </>
                    );
                }
            }
        },
        {
            title: t('prescriptionEnd'),
            dataIndex: 'uvv_einddatum_recept',
            key: 'uvv_einddatum_recept',
        },
        {
            title: t('medication'),
            dataIndex: 'medication',
            key: 'medication',
        },
        {
            title: t('status'),
            key: 'status',
            render: (index, record) => {
                let icon = <span></span>;
                if (record.status === "Actief") {
                    icon = <CheckCircleTwoTone style={{marginRight: 5}} twoToneColor={"#71ff7d"} />;
                }
                if (record.status === "On hold") {
                    icon = <PauseCircleTwoTone style={{marginRight: 5}} twoToneColor={"#ff0000"} />;
                }
                if (record.status === "Nieuw") {
                    icon = <HourglassTwoTone style={{marginRight: 5}} twoToneColor={"#ffae00"} />;
                }
                if (record.status === "Toegewezen") {
                    icon = <UserSwitchOutlined style={{marginRight: 5, color: "#8fa6ff"}} />;
                }
                if (record.status === "Ingepland") {
                    icon = <CalendarTwoTone style={{marginRight: 5}} twoToneColor={"#8fa6ff"} />;
                }
                if (record.status === "Afgerond") {
                    icon = <CheckCircleTwoTone style={{marginRight: 5}} twoToneColor={"#71ff7d"} />;
                }

                return (
                    <>
                        {icon} <span>{record.status}</span>
                    </>
                )
            }
        },
        {
            title: '',
            key: 'edit_actions',
            render: (index, record) => {
                if (record.rowType === "uvo") {
                    return <span></span>;
                }
                return (
                    <Button
                        icon={<FilePdfOutlined/>}
                        ghost
                        shape={"round"}
                        type={"primary"}
                        title={"Download in PDF"}
                        onClick={async () => {
                            await downloadPdf(record);
                        }} />
                );
            }
        },
    ];

    if (selectedCareRequest) {
        return (<CareRequest
            onClose={() => setSelectedCareRequest(undefined)}
            careRequest={selectedCareRequest} />);
    }

    return (
        <>
            {showForm && <CareRequestForm
                editRecord={editRecord}
                renewRecord={renewRecord}
                onClose={() => {
                    setShowForm(false);
                    setEditRecord(undefined);
                    setRenewRecord(undefined);
                    getCareRequests();
                }}
            />}

            <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title="Recepten"
                subTitle={t("overview")}
                /*extra={[
                    <Button key="1" type="primary" onClick={() => setShowForm(true)}>
                        <PlusCircleOutlined />
                        {t("newRequest")}
                    </Button>,
                ]}*/
            />

            <Form layout="vertical">
                <Row gutter={[16,8]} style={{paddingTop: 20}}>
                    <Col sm={6}>
                        <Form.Item label={"Zoeken"}>
                            <Input.Search
                                placeholder="Zoeken"
                                onSearch={value => setSearchQuery(value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col sm={6}>
                        <Form.Item label={"Medicatie"}>
                            <Select style={{width: "100%"}} value={selectedMedication} onChange={(value) => setSelectedMedication(value)}>
                                <Select.Option value="">Alle</Select.Option>
                                {medications.map((m, index) => {
                                    return <Select.Option value={m} key={index}>{m}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum vanaf"}>
                            <DatePicker value={dateFrom} onChange={(e) => setDateFrom(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum t/m"}>
                            <DatePicker value={dateUntil} onChange={(e) => setDateUntil(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Button icon={<FileExcelOutlined/>} style={{marginRight: 10}} onClick={exportExcel} loading={downloading}>Export Excel</Button>
                    <Button icon={<FilePdfOutlined/>}>Export PDF</Button>
                </Col>
            </Row>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Table
                        scroll={{ x: 1000 }}
                        pagination={paginationActive}
                        onChange={handleChangeActive}
                        loading={loading}
                        className={"custom-table"}
                        expandedRowKeys={expandedRowKeys}
                        onExpand={(expanded, record) => setExpandedRowKeys(expanded ? [record.key] : [])}
                        rowClassName={(record) => {
                            if (record.rowType === "uvo") {
                                return "child-row-level-1";
                            }
                            return "parent-row";
                        }}
                        dataSource={careRequestsActive}
                        columns={columns} />
                </Col>
            </Row>
        </>
    );
};

export default CareRequests;

