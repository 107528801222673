import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Form, Input, Modal, PageHeader, Row, Select, Table, Tabs, DatePicker, Pagination} from 'antd';
import {
    CalendarTwoTone,
    CheckCircleTwoTone,
    HourglassTwoTone,
    PauseCircleTwoTone,
    UserSwitchOutlined,
    CalendarOutlined, PlusCircleOutlined, FileExcelOutlined, TagOutlined, EditOutlined, PauseOutlined, ReloadOutlined,
} from '@ant-design/icons';
import CareRequestForm from "./CareRequestForm";
import UserService from "./userService";
import CareRequest from "./CareRequest";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {useTranslation} from 'react-i18next';
import 'moment/locale/nl';
import {debounce} from "lodash"

moment.locale("nl");

const OnHoldDialog = (props) => {
    const [reason, setReason] = useState(undefined);
    const [lastReasonDesc, setLastReasonDesc] = useState("");
    const [reasonExtraInfo, setReasonExtraInfo] = useState("");
    const [date, setDate] = useState(moment());
    const [record, setRecord] = useState({
        patientId: "",
    });

    useEffect(() => {
        async function setVars() {
            if (props.record && props.record.patientId) {
                await setReason(undefined);
                await setReasonExtraInfo("");
                await setLastReasonDesc("");
                await setRecord(props.record);

                let reasonDesc = "Onbekend";
                if (props.record.uvvOnHoldReason === 0) {
                    reasonDesc = "Patiënt is in zorg";
                }
                if (props.record.uvvOnHoldReason === 1) {
                    reasonDesc = "Prik is/wordt gegeven in het ziekenhuis";
                }
                if (props.record.uvvOnHoldReason === 2) {
                    reasonDesc = "Patiënt is ziek";
                }
                if (props.record.uvvOnHoldReason === 3) {
                    reasonDesc = "Zorgprogramma stopt";
                }
                if (props.record.uvvOnHoldReason === 4) {
                    reasonDesc = "Patiënt is opgenomen in het ziekenhuis";
                }
                if (props.record.uvvOnHoldReason === 5) {
                    reasonDesc = "Patiënt of ouder kan niet";
                }
                if (props.record.uvvOnHoldReason === 7) {
                    reasonDesc = "Overig";
                }
                await setLastReasonDesc(reasonDesc);
            }
        }

        setVars();
    }, [props.record]);

    const postOnHold = async () => {
        const c = window.confirm("Weet je zeker dat je de situatie van dit uitvoeringsverzoek wilt wijzigen?");

        if (c) {
            const request = await UserService.authFetch("/v1/uitvoeringsverzoeken_up/" + record.id + "/onderdelen/" + record.lastUvoId + "?onhold=1", {
                method: "post",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cancelledReason: reason,
                    overigOmschrijving: reasonExtraInfo,
                    clientGesproken: 0
                })
            });
            if (request.ok) {
                props.onSuccess();
            }
        }
    };

    return (
        <Modal
            visible={props.visible}
            title={"On hold melden: " + record.patientId}
            onCancel={props.onClose}
            footer={[
                <Alert
                    style={{marginBottom: 20, textAlign: "left", display: reason !== undefined ? "block" : "none"}}
                    message="Let op!"
                    description="Bekijk goed of de wijziging klopt. De verpleegkundige krijgt een SMS bericht van elke wijziging."
                    type="error"
                    showIcon
                />,
                <Button key="back" onClick={props.onClose}>
                    Sluiten
                </Button>,
                <Button key="submit" type="primary" disabled={reason === undefined || date === undefined || (reason === "7" && !reasonExtraInfo.trim().length)} onClick={(reason !== undefined && date !== undefined) ? () => {
                    postOnHold();
                } : undefined}>
                    Wijziging doorgeven
                </Button>,
            ]}
        >
            <Alert
                style={{marginBottom: 20}}
                message="Wat is een on hold melding?"
                description="We gebruiken de term 'on hold' voor het tijdelijk of definitief stoppen van de uitvoering van de zorg voor een uitvoeringsverzoek of het doorgeven van een verzoek tot wijzigen van de uitvoeringsdatum. Zodra de situatie rondom een patient wijzigt, dient dit verzoek ingediend te worden."
                type="info"
                showIcon
            />

            <Form.Item label="Huidige of laatst bekende situatie">
                <Input type={"text"} value={lastReasonDesc} disabled readOnly />
            </Form.Item>

            <Form.Item label="Wat wordt de nieuwe situatie?">
                <Select size={"large"} style={{width: 400}} placeholder={"Kies een reden..."} value={reason} onChange={(e) => setReason(e)}>
                    <Select.OptGroup label={"Situatie is weer normaal"}>
                        <Select.Option value={0}>De patiënt is (terug) in zorg</Select.Option>
                    </Select.OptGroup>
                    <Select.OptGroup label={"Situatie is on hold"}>
                        <Select.Option value={1}>Prik is/wordt gegeven in het ziekenhuis</Select.Option>
                        <Select.Option value={2}>Patiënt is ziek</Select.Option>
                        <Select.Option value={3}>Zorgprogramma stopt</Select.Option>
                        <Select.Option value={4}>Patiënt is opgenomen in het ziekenhuis</Select.Option>
                        <Select.Option value={5}>Patiënt of ouder kan niet</Select.Option>
                        <Select.Option value={7}>Overig</Select.Option>
                    </Select.OptGroup>
                </Select>
            </Form.Item>

            {reason === 0 && <Alert
                style={{marginBottom: 20}}
                message={"Patient is (terug) in zorg"}
                description="De patiënt wordt terug in zorg gemeld en een eventuele bestaande afspraak wordt weer actief. Als er een nieuwe afspraak gemaakt moet worden, wordt de verpleegkundige hiervan automatisch op de hoogte gesteld."
                type="warning"
                showIcon
            />}

            {(reason === 1 || reason === 5 || reason === 7) && <Alert
                style={{marginBottom: 20}}
                message={"Verpleegkundige maakt nieuwe afspraak"}
                description={"De bestaande afspraak wordt geannuleerd en de verpleegkundige ontvangt automatisch het verzoek om direct een nieuwe afspraak te maken met de patiënt"}
                type="warning"
                showIcon
            />}

            {(reason === 2 || reason === 4 || reason === 8) && <Alert
                style={{marginBottom: 20}}
                message={"Verpleegkundige wacht op nader bericht"}
                description={"De bestaande afspraak gaat niet door en de verpleegkundige wacht totdat de status weer op 'De patiënt is (terug) in zorg' wordt gezet. De verpleegkundige ontvangt automatisch een bericht."}
                type="warning"
                showIcon
            />}

            {(reason === 3) && <Alert
                style={{marginBottom: 20}}
                message={"Zorgprogramma stopt"}
                description={"Het gehele zorgprogramma voor deze patiënt stopt. Alle toekomstige afspraken worden definitief geannuleerd."}
                type="warning"
                showIcon
            />}

            {reason === 7 && <Form.Item label="Geef extra informatie over de wijziging/on hold en de reden daarvan">
                <TextArea
                    placeholder={"Vul hier extra informatie in over de wijziging."}
                    value={reasonExtraInfo}
                    onChange={(e) => setReasonExtraInfo(e.target.value)} />
            </Form.Item>}
        </Modal>
    );
};

const CareRequests = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [changeRecord, setChangeRecord] = useState(undefined);
    const [editRecord, setEditRecord] = useState(undefined);
    const [onHoldRecord, setOnHoldRecord] = useState(undefined);
    const [renewRecord, setRenewRecord] = useState(undefined);
    const [careRequestsActive, setCareRequestsActive] = useState([]);
    const [careRequestsHistory, setCareRequestsHistory] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [selfcareFilter, setSelfcareFilter] = useState(-2);
    const [dateUntil, setDateUntil] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [showOnholdModal, setShowOnholdModal] = useState(false);
    const [selectedCareRequest, setSelectedCareRequest] = useState(undefined);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [numRequestsActive, setNumRequestsActive] = useState(0);
    const [numRequestsHistory, setNumRequestsHistory] = useState(0);
    const [paginationActive, setPaginationActive] = useState({
        pageSize: 10
    });
    const [paginationHistory, setPaginationHistory] = useState({
        pageSize: 10
    });
    const [downloading, setDownloading] = useState(false);
    const [medications, setMedications] = useState([]);
    const [selectedMedication, setSelectedMedication] = useState("");

    const handleChangeActive = (pagination, filters, sorter) => {
        getCareRequests(pagination.current)
    };

    const handleChangeHistory = (pagination, filters, sorter) => {
        getCareRequestsHistory(pagination.current)
    };

    const getCareRequests = async (page) => {
        if (page === undefined) {
            page = 1;
        }

        await setLoading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        const requestNumActive = await UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?count=1&active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&medication=" + selectedMedication + "&selfcare=" + selfcareFilter + "&search=" + searchQuery, {
            method: "get"
        });
        const responseNumActive = await requestNumActive.json();
        await setNumRequestsActive(responseNumActive.numUvv);
        await setPaginationActive((pager) => {
            let newPager = pager;
            newPager.total = responseNumActive.numUvv;

            return newPager;
        });

        const request = await UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&page=" + page + "&medication=" + selectedMedication + "&selfcare=" + selfcareFilter + "&search=" + searchQuery, {
            method: "get"
        });
        const response = await request.json();
        await setCareRequestsActive(response.items);
        if (!medications.length) {
            await setMedications(response.medication);
        }
        await setLoading(false);
    };

    const getCareRequestsHistory = async (page) => {
        if (page === undefined) {
            page = 1;
        }

        await setLoading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        const requestNumHistory = await UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?count=1&active=0&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&selfcare=" + selfcareFilter + "&search=" + searchQuery, {
            method: "get"
        });
        const responseNumHistory = await requestNumHistory.json();
        await setNumRequestsHistory(responseNumHistory.numUvv);
        await setPaginationHistory((pager) => {
            let newPager = pager;
            newPager.total = responseNumHistory.numUvv;

            return newPager;
        });

        const requestHistory = await UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?active=0&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&selfcare=" + selfcareFilter + "&page=" + page + "&search=" + searchQuery, {
            method: "get"
        });
        const responseHistory = await requestHistory.json();
        await setCareRequestsHistory(responseHistory.items);
        await setLoading(false);
    };

    useEffect(() => {
        getCareRequests(1);
        getCareRequestsHistory(1);
    }, [dateFrom, dateUntil, searchQuery, selectedMedication, selfcareFilter]);

    const exportExcel = async () => {
        await setDownloading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        return UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?active=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&medication=" + selectedMedication + "&selfcare=" + selfcareFilter + "&search=" + searchQuery + "&mode=download", {
            method: "GET",
        }).then(function(response){
            return response.blob();
        }).then(function(response){
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                const reader = new window.FileReader();
                reader.readAsDataURL(response);
                reader.onload = function() {
                    let fileName = "Yulp-Export";

                    let dataUrl = reader.result;
                    let element = document.createElement("a");
                    element.setAttribute("href", dataUrl);
                    element.setAttribute("download", fileName + ".xlsx");

                    element.style.display = "none";
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                };
            }
            else {
                alert("Uw browser ondersteund deze functionaliteit niet...");
            }

            setDownloading(false);
        });
    };

    const columns = [
        {
            title: 'Patient ID',
            key: 'patientId',
            fixed: 'left',
            width: 250,
            render: (index, record) => {
                if (record.rowType === "uvo") { // Afspraak, child row
                    return <span><CalendarOutlined /> {record.patientId}</span>
                }
                return (
                    <Button icon={<TagOutlined/>} type={"secondary"} onClick={() => setSelectedCareRequest(record)}>{record.patientId}</Button>
                );
            }
        },
        {
            title: 'Referentie',
            fixed: 'left',
            width: 150,
            dataIndex: 'ownReference',
            key: 'ownReference',
        },
        {
            title: t('name'),
            dataIndex: 'patientName',
            key: 'patientName',
        },
        {
            title: 'Geb. datum',
            dataIndex: 'patientDob',
            key: 'patientDob',
        },
        {
            title: 'Plaats',
            dataIndex: 'patientCity',
            key: 'patientCity',
        },
        {
            title: t('registrationDate'),
            dataIndex: 'dateReceived',
            key: 'dateReceived',
        },
        {
            title: t('deliveryDate'),
            dataIndex: 'dateDelivery',
            key: 'dateDelivery',
        },
        {
            title: "Datum verlengd",
            dataIndex: 'dateRenewed',
            key: 'dateRenewed',
        },
        {
            title: t('preferentProcedureDate'),
            key: 'dateAppointment',
            render: (index, record) => {
                let m = false;
                if (record.dateAppointment !== "Nog in te plannen") {
                    m = record.rowType === "uvv" ? moment(record.dateAppointment, "DD-MM-YYYY") : moment(record.dateAppointment, "DD-MM-YYYY HH:mm");
                }

                if (record.rowType === "uvv") {
                    return (
                        <>
                            <span>{record.rowType === "uvv" ? record.dateAppointment + " (voorkeur)" : t("waitingForAppointment")}</span>
                            <br />
                            <small>{m && m.fromNow()}</small>
                        </>
                    )
                }
                else {
                    if (record.dateAppointment === "Nog in te plannen") {
                        return "Nog in te plannen";
                    }
                    return (
                        <>
                            <span>{m.format("dd DD-MM-YYYY") + " om " + m.format("HH:mm") + "u"}</span>
                            <br />
                            <small>{m && m.fromNow()}</small>
                        </>
                    );
                }
            }
        },
        {
            title: t('prescriptionEnd'),
            dataIndex: 'uvv_einddatum_recept',
            key: 'uvv_einddatum_recept',
        },
        {
            title: t('medication'),
            dataIndex: 'medication',
            key: 'medication',
        },
        {
            title: t('type'),
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: "# handelingen",
            dataIndex: 'aantal_handelingen',
            key: 'aantal_handelingen',
        },
        {
            title: "# handelingen resterend",
            dataIndex: 'aantal_handelingen_resterend',
            key: 'aantal_handelingen_resterend',
        },
        {
            title: t('status'),
            key: 'status',
            render: (index, record) => {
                let icon = <span></span>;
                if (record.status === "Actief") {
                    icon = <CheckCircleTwoTone style={{marginRight: 5}} twoToneColor={"#71ff7d"} />;
                }
                if (record.status === "On hold") {
                    icon = <PauseCircleTwoTone style={{marginRight: 5}} twoToneColor={"#ff0000"} />;
                }
                if (record.status === "Nieuw") {
                    icon = <HourglassTwoTone style={{marginRight: 5}} twoToneColor={"#ffae00"} />;
                }
                if (record.status === "Toegewezen") {
                    icon = <UserSwitchOutlined style={{marginRight: 5, color: "#8fa6ff"}} />;
                }
                if (record.status === "Ingepland") {
                    icon = <CalendarTwoTone style={{marginRight: 5}} twoToneColor={"#8fa6ff"} />;
                }
                if (record.status === "Afgerond") {
                    icon = <CheckCircleTwoTone style={{marginRight: 5}} twoToneColor={"#71ff7d"} />;
                }
                if (record.selfCare && record.selfCare.length) {
                    icon = <PauseCircleTwoTone style={{marginRight: 5}} twoToneColor={"#53c169"} />;
                }

                return (
                    <>
                        {icon} <span>{record.status}</span>
                    </>
                )
            }
        },
    ];

    if (selectedCareRequest) {
        return (<CareRequest
            onClose={() => setSelectedCareRequest(undefined)}
            careRequest={selectedCareRequest} />);
    }

    return (
        <div>
            {showForm && <CareRequestForm
                editRecord={editRecord}
                renewRecord={renewRecord}
                onClose={() => {
                    setShowForm(false);
                    setEditRecord(undefined);
                    setRenewRecord(undefined);
                    getCareRequests();
                }}
            />}

            <Modal
                visible={false}
                title={changeRecord && "Een wijziging doorgeven: " + changeRecord.patientId}
                onCancel={() => setChangeRecord(undefined)}
                afterClose={() => setChangeRecord(undefined)}
                footer={[]}
            >
                <p>Je wilt de situatie van een uitvoeringsverzoek wijzigen. De verschillende opties staan hier weergegeven.</p>

                <Divider>Het uitvoeringsverzoek wijzigt</Divider>
                <Alert
                    style={{marginBottom: 20}}
                    description="Gebruik deze optie om wijzigingen door te geven rond het uitvoeringsverzoek. Let op: niet alle velden zijn wijzigbaar."
                    type="info"
                    showIcon
                />
                <Button
                    icon={<CalendarOutlined/>}
                    ghost
                    shape={"round"}
                    type={"primary"}
                    title={"Afspraak verzetten"}
                    onClick={async () => {
                        await setEditRecord(changeRecord);
                        await setChangeRecord(undefined);
                        await setShowForm(true);
                    }}>Uitvoeringsverzoek wijzigen</Button>

                <Divider>De situatie rondom de patiënt wijzigt</Divider>
                <Alert
                    style={{marginBottom: 20}}
                    description="Gebruik deze optie om een wijziging rondom de patiënt door te geven. Bijvoorbeeld als de afspraak niet door kan gaan of juist om de patiënt weer terug in zorg te melden."
                    type="info"
                    showIcon
                />
                <Button
                    icon={<PauseOutlined/>}
                    ghost
                    shape={"round"}
                    type={"primary"}
                    title={"De situatie van de patient wijzigt: on hold melden"}
                    onClick={async () => {
                        await setOnHoldRecord(changeRecord);
                        await setChangeRecord(undefined);
                        await setShowOnholdModal(true);
                    }}>Wijziging patiënt doorgeven</Button>
            </Modal>

            <OnHoldDialog
                visible={showOnholdModal}
                record={onHoldRecord}
                onClose={() => {
                    setOnHoldRecord(undefined);
                    setShowOnholdModal(false);
                }}
                onSuccess={() => {
                    setOnHoldRecord(undefined);
                    setShowOnholdModal(false);
                    getCareRequests();
                }}
            />

            <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title={t("careRequests")}
                subTitle={t("overview")}
                /*extra={[
                    <Button key="1" type="primary" onClick={() => setShowForm(true)}>
                        <PlusCircleOutlined />
                        {t("newRequest")}
                    </Button>,
                ]}*/
            />

            <Form layout="vertical">
                <Row gutter={[16,8]} style={{paddingTop: 20}}>
                    <Col sm={6}>
                        <Form.Item label={"Zoeken"}>
                            <Input.Search
                                placeholder="Zoeken"
                                onSearch={value => setSearchQuery(value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col sm={6}>
                        <Form.Item label={"Medicatie"}>
                            <Select style={{width: "100%"}} value={selectedMedication} onChange={(value) => setSelectedMedication(value)}>
                                <Select.Option value="">Alle</Select.Option>
                                {medications.map((m, index) => {
                                    return <Select.Option value={m} key={index}>{m}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum vanaf"}>
                            <DatePicker value={dateFrom} onChange={(e) => setDateFrom(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum t/m"}>
                            <DatePicker value={dateUntil} onChange={(e) => setDateUntil(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Zelfzorg"}>
                            <Select
                                style={{ width: 150 }}
                                value={selfcareFilter}
                                options={[
                                    { value: -2, label: "Ja/Nee"},
                                    { value: -1, label: "Ja"},
                                    { value: 0, label: "Nee"},
                                ]}
                                onChange={(e) => setSelfcareFilter(e)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Button icon={<FileExcelOutlined/>} style={{marginRight: 10}} onClick={exportExcel} loading={downloading}>Export Excel</Button>
                </Col>
            </Row>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Tabs>
                        <Tabs.TabPane tab={"Actief"} key={0}>
                            <Table
                                scroll={{ x: 2500 }}
                                pagination={paginationActive}
                                onChange={handleChangeActive}
                                loading={loading}
                                expandedRowKeys={expandedRowKeys}
                                childrenColumnName={"appointments"}
                                onExpand={(expanded, record) => setExpandedRowKeys(expanded ? [record.key] : [])}
                                dataSource={careRequestsActive}
                                columns={columns} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={"Historie"} key={1}>
                            <Table
                                scroll={{ x: 2500 }}
                                pagination={paginationHistory}
                                onChange={handleChangeHistory}
                                loading={loading}
                                className={"custom-table"}
                                expandedRowKeys={expandedRowKeys}
                                onExpand={(expanded, record) => setExpandedRowKeys(expanded ? [record.key] : [])}
                                childrenColumnName={"appointments"}
                                rowClassName={(record) => {
                                    if (record.rowType === "uvo") {
                                        return "child-row-level-1";
                                    }
                                    return "parent-row";
                                }}
                                dataSource={careRequestsHistory}
                                columns={columns} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
    );
};

export default CareRequests;
