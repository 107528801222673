import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    PageHeader,
    Row,
    Select,
    Table,
    Tabs,
    DatePicker,
    Pagination,
    InputNumber, notification
} from 'antd';
import {
    CalendarTwoTone,
    CheckCircleTwoTone,
    HourglassTwoTone,
    PauseCircleTwoTone,
    UserSwitchOutlined,
    CalendarOutlined, PlusCircleOutlined, FileExcelOutlined, TagOutlined, EditOutlined, PauseOutlined, ReloadOutlined,
} from '@ant-design/icons';
import CareRequestForm from "./CareRequestForm";
import UserService from "./userService";
import CareRequest from "./CareRequest";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {useTranslation} from 'react-i18next';
import 'moment/locale/nl';
import {debounce} from "lodash"
import {useForm} from "antd/lib/form/Form";

moment.locale("nl");

const CareRequests = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [changeRecord, setChangeRecord] = useState({
        aantal_handelingen: 0,
    });
    const [editRecord, setEditRecord] = useState(undefined);
    const [onHoldRecord, setOnHoldRecord] = useState(undefined);
    const [renewRecord, setRenewRecord] = useState(undefined);
    const [careRequestsActive, setCareRequestsActive] = useState([]);
    const [careRequestsHistory, setCareRequestsHistory] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [selfcareFilter, setSelfcareFilter] = useState(-2);
    const [dateUntil, setDateUntil] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [showOnholdModal, setShowOnholdModal] = useState(false);
    const [selectedCareRequest, setSelectedCareRequest] = useState(undefined);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [numRequestsActive, setNumRequestsActive] = useState(0);
    const [numRequestsHistory, setNumRequestsHistory] = useState(0);
    const [paginationActive, setPaginationActive] = useState({
        pageSize: 10
    });
    const [paginationHistory, setPaginationHistory] = useState({
        pageSize: 10
    });
    const [downloading, setDownloading] = useState(false);
    const [medications, setMedications] = useState([]);
    const [selectedMedication, setSelectedMedication] = useState("");
    const [prolongExtraAppointments, setProlongExtraAppointments] = useState(0);
    const [prolongAppointmentDate, setProlongAppointmentDate] = useState(null);
    const [prolongFrequencyInfo, setProlongFrequencyInfo] = useState(null);
    const [renewExtraAppointments, setRenewExtraAppointments] = useState("");
    const [renewAppointmentDate, setRenewAppointmentDate] = useState(null);
    const [renewFrequencyInfo, setRenewFrequencyInfo] = useState(null);
    const [stopDate, setStopDate] = useState(null);
    const [newDeliveryDate, setNewDeliveryDate] = useState(null);
    const [prolongNewDeliveryDate, setProlongNewDeliveryDate] = useState(null);
    const [renewNewDeliveryDate, setRenewNewDeliveryDate] = useState(null);
    const [synagisAppointmentDate, setSynagisAppointmentDate] = useState(null);

    const [changeForm] = useForm()

    const openNotificationWithIcon = (type, title, description) => {
        notification[type]({
            message: title,
            description: description,
            placement: "bottomLeft",
            duration: 5,
            bottom: 50
        });
    };

    const updateUvv = async (values) => {
        // return console.log(values);
        const request = await UserService.authFetch("/v1/uitvoeringsverzoeken_new", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(values)
        });
        const response = await request.json();

        if (response && response.re_id) {
            openNotificationWithIcon("success", "Wijziging doorgegeven", "De wijziging is opgeslagen.");
            setChangeRecord({
                aantal_handelingen: 0,
            });
            getCareRequests(1);
        }
    }

    const stopUvv = async () => {
        const c = window.confirm("Weet je zeker dat je dit uitvoeringsverzoek wil stoppen? Het uitvoeringsverzoek zal niet meer zichtbaar zijn en alle toekomstige afspraken worden geannuleerd.");

        if (c) {
            const request = await UserService.authFetch("/v1/uitvoeringsverzoeken_up/" + changeRecord.id + "/onderdelen/" + changeRecord.lastUvoId + "?onhold=1", {
                method: "post",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cancelledReason: 3,
                    overigOmschrijving: "GESTOPT DOOR OPDRACHTGEVER",
                    clientGesproken: 0,
                    stopDate: stopDate.format("YYYY-MM-DD")
                })
            });
            if (request.ok) {
                openNotificationWithIcon("success", "Wijziging doorgegeven", "De wijziging is opgeslagen.");
                setChangeRecord({
                    aantal_handelingen: 0,
                });
                getCareRequests(1);
            }
        }
    };

    const handleChangeActive = (pagination, filters, sorter) => {
        getCareRequests(pagination.current)
    };

    const getCareRequests = async (page) => {
        if (page === undefined) {
            page = 1;
        }

        await setLoading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        const requestNumActive = await UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?count=1&active=1&expiring=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&medication=" + selectedMedication + "&selfcare=0&search=" + searchQuery, {
            method: "get"
        });
        const responseNumActive = await requestNumActive.json();
        await setNumRequestsActive(responseNumActive.numUvv);
        await setPaginationActive((pager) => {
            let newPager = pager;
            newPager.total = responseNumActive.numUvv;

            return newPager;
        });

        const request = await UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?active=1&expiring=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&page=" + page + "&medication=" + selectedMedication + "&selfcare=0&search=" + searchQuery, {
            method: "get"
        });
        const response = await request.json();
        await setCareRequestsActive(response.items);
        if (!medications.length) {
            await setMedications(response.medication);
        }
        await setLoading(false);
    };

    useEffect(() => {
        getCareRequests(1);
    }, [dateFrom, dateUntil, searchQuery, selectedMedication, selfcareFilter]);

    const exportExcel = async () => {
        await setDownloading(true);
        const me = await UserService.getDecodedToken();

        let dateFromFormat = null;
        if (dateFrom) {
            dateFromFormat = dateFrom.format('YYYY-MM-DD');
        }

        let dateUntilFormat = null;
        if (dateUntil) {
            dateUntilFormat = dateUntil.format('YYYY-MM-DD');
        }

        return UserService.authFetch("/v1/users/" + me.id + "/uitvoeringsverzoeken/all?active=1&expiring=1&dateFrom=" + dateFromFormat + "&dateUntil=" + dateUntilFormat + "&medication=" + selectedMedication + "&selfcare=" + selfcareFilter + "&search=" + searchQuery + "&mode=download", {
            method: "GET",
        }).then(function(response){
            return response.blob();
        }).then(function(response){
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                const reader = new window.FileReader();
                reader.readAsDataURL(response);
                reader.onload = function() {
                    let fileName = "Yulp-Export";

                    let dataUrl = reader.result;
                    let element = document.createElement("a");
                    element.setAttribute("href", dataUrl);
                    element.setAttribute("download", fileName + ".xlsx");

                    element.style.display = "none";
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                };
            }
            else {
                alert("Uw browser ondersteund deze functionaliteit niet...");
            }

            setDownloading(false);
        });
    };

    const columns = [
        {
            title: 'Patient ID',
            key: 'patientId',
            fixed: 'left',
            width: 250,
            render: (index, record) => {
                if (record.rowType === "uvo") { // Afspraak, child row
                    return <span><CalendarOutlined /> {record.patientId}</span>
                }
                return (
                    <Button icon={<TagOutlined/>} type={"secondary"} onClick={() => setSelectedCareRequest(record)}>{record.patientId}</Button>
                );
            }
        },
        {
            title: 'Referentie',
            fixed: 'left',
            width: 150,
            dataIndex: 'ownReference',
            key: 'ownReference',
        },
        {
            title: '# handelingen',
            fixed: 'left',
            width: 130,
            dataIndex: 'aantal_handelingen',
            key: 'numVisits',
        },
        {
            title: '# hand. resterend',
            fixed: 'left',
            width: 130,
            dataIndex: 'aantal_handelingen_resterend',
            key: 'numVisitsLeft',
        },
        {
            title: 'Laatste afspraak',
            width: 150,
            key: 'latest_appointment',
            render: (row) => {
                const apt = row && row.appointments && row.appointments[0] ? row.appointments[0].dateAppointmentDate : "Nog in te plannen";

                return <span>{apt}</span>
            }
        },
        {
            title: t('name'),
            dataIndex: 'patientName',
            key: 'patientName',
        },
        {
            title: 'Plaats',
            dataIndex: 'patientCity',
            key: 'patientCity',
        },
        {
            title: t('registrationDate'),
            dataIndex: 'dateReceived',
            key: 'dateReceived',
        },
        {
            title: t('medication'),
            dataIndex: 'medication',
            key: 'medication',
        },
        {
            title: t('type'),
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: t('status'),
            key: 'status',
            render: (index, record) => {
                let icon = <span></span>;
                if (record.status === "Actief") {
                    icon = <CheckCircleTwoTone style={{marginRight: 5}} twoToneColor={"#71ff7d"} />;
                }
                if (record.status === "On hold") {
                    icon = <PauseCircleTwoTone style={{marginRight: 5}} twoToneColor={"#ff0000"} />;
                }
                if (record.status === "Nieuw") {
                    icon = <HourglassTwoTone style={{marginRight: 5}} twoToneColor={"#ffae00"} />;
                }
                if (record.status === "Toegewezen") {
                    icon = <UserSwitchOutlined style={{marginRight: 5, color: "#8fa6ff"}} />;
                }
                if (record.status === "Ingepland") {
                    icon = <CalendarTwoTone style={{marginRight: 5}} twoToneColor={"#8fa6ff"} />;
                }
                if (record.status === "Afgerond") {
                    icon = <CheckCircleTwoTone style={{marginRight: 5}} twoToneColor={"#71ff7d"} />;
                }
                if (record.selfCare && record.selfCare.length) {
                    icon = <PauseCircleTwoTone style={{marginRight: 5}} twoToneColor={"#53c169"} />;
                }

                return (
                    <>
                        {icon} <span>{record.status}</span>
                    </>
                )
            }
        },
    ];

    useEffect(() => {
        changeForm.resetFields();
        changeForm.setFieldsValue({
            frequency_times: changeRecord?.frequency?.times,
            frequency_period: changeRecord?.frequency?.period,
            frequency_amount: changeRecord?.frequency?.amount,
            frequency_unit: changeRecord?.frequency?.unit,
            prolong_frequency_info: changeRecord?.opmerking_vpk,
            renew_frequency_info: changeRecord?.opmerking_vpk,
            stop_date: changeRecord?.uvv_einddatum_recept_sql ? moment(changeRecord?.uvv_einddatum_recept_sql) : undefined,
            new_delivery_date: changeRecord?.uvv_leverdatum_sql ? moment(changeRecord?.uvv_leverdatum_sql) : undefined,
            prolong_new_delivery_date: changeRecord?.uvv_leverdatum_sql ? moment(changeRecord?.uvv_leverdatum_sql) : undefined,
            renew_new_delivery_date: changeRecord?.uvv_leverdatum_sql ? moment(changeRecord?.uvv_leverdatum_sql) : undefined,
            new_delivery_date_timeframe: changeRecord?.uvv_leverdatum_tijdsvak ?? undefined,
            prolong_new_delivery_date_timeframe: changeRecord?.uvv_leverdatum_tijdsvak ?? undefined,
            renew_new_delivery_date_timeframe: changeRecord?.uvv_leverdatum_tijdsvak ?? undefined,
        })
        if (changeRecord?.uvv_einddatum_recept_sql) {
            setStopDate(moment(changeRecord?.uvv_einddatum_recept_sql));
        }
        if (changeRecord?.uvv_leverdatum_sql) {
            setNewDeliveryDate(moment(changeRecord?.uvv_leverdatum_sql));
        }
    }, [changeRecord])

    if (selectedCareRequest) {
        return (<CareRequest
            onClose={() => setSelectedCareRequest(undefined)}
            careRequest={selectedCareRequest} />);
    }

    return (
        <div>
            <Modal
                visible={false}
                title={changeRecord && "Een wijziging doorgeven: " + changeRecord.patientId}
                onCancel={() => setChangeRecord({
                    aantal_handelingen: 0,
                })}
                afterClose={() => setChangeRecord({
                    aantal_handelingen: 0,
                })}
                footer={[]}
                width={800}
                style={{ width: 600 }}
            >
                <Form layout="vertical" form={changeForm}>
                    <p>Je kunt voor dit uitvoeringsverzoek extra handelingen instellen, zodat het uitvoeringsverzoek doorloopt, of het verzoek stopzetten.</p>

                    <Tabs>
                        <Tabs.TabPane tab="Verlengen" key="verlengen">
                            <Divider>Ik wil het recept verlengen</Divider>
                            <Alert
                              style={{marginBottom: 20}}
                              description="Gebruik deze optie als het uitvoeringsverzoek door mag gaan met dezelfde dosering en frequentie."
                              type="info"
                              showIcon
                            />
                            <Row gutter={16}>
                                <Col>
                                    <Form.Item name="prolong_extra_appointments" label="Extra handelingen">
                                        <InputNumber min={0} onChange={(val) => setProlongExtraAppointments(val)} />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="prolong_appointment_date" label="Volgende gewenste toediening">
                                        <DatePicker onChange={(d) => setProlongAppointmentDate(d)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col>
                                    <strong>Het nieuwe totaal aantal handelingen wordt: {changeRecord?.aantal_handelingen + prolongExtraAppointments}</strong>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col>
                                    <Form.Item label="Nieuwe leverdatum" name="prolong_new_delivery_date">
                                        <DatePicker onChange={(d) => setProlongNewDeliveryDate(d)} format="DD-MM-YYYY" />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Nieuwe leverdatum tijdvak" name="prolong_new_delivery_date_timeframe">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col>
                                    <br />
                                    <Form.Item label={t('frequencyInfo')} name="prolong_frequency_info" rules={[{ required: false }]}>
                                        <TextArea rows={2} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col>
                                    <Button disabled={!prolongAppointmentDate} onClick={() => updateUvv({
                                        action: "prolong",
                                        id: changeRecord.re_id,
                                        re_id: changeRecord.re_id,
                                        uvv_id: changeRecord.id,
                                        bsn: changeRecord.bsn,
                                        extra_appointments: changeForm.getFieldValue('prolong_extra_appointments'),
                                        num_appointments: (changeRecord.aantal_handelingen + changeForm.getFieldValue('prolong_extra_appointments')),
                                        appointment_date: prolongAppointmentDate.format("YYYY-MM-DD"),
                                        frequency_info: changeForm.getFieldValue('prolong_frequency_info'),
                                        leverdatum: prolongNewDeliveryDate.format("YYYY-MM-DD"),
                                        leverdatum_tijdsvak: changeForm.getFieldValue('prolong_new_delivery_date_timeframe'),
                                    })}>Verlenging opslaan</Button>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Vernieuwen" key="vernieuwen">
                            <Divider>Ik wil het recept vernieuwen</Divider>
                            <Alert
                              style={{marginBottom: 20}}
                              description="Gebruik deze optie als het uitvoeringsverzoek door mag gaan met een wijziging in de dosering of frequentie."
                              type="info"
                              showIcon
                            />
                            <Row gutter={16}>
                                <Col>
                                    <Form.Item name="renew_extra_appointments" label="Extra handelingen">
                                        <InputNumber min={0} onChange={(val) => setRenewExtraAppointments(val)} />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="renew_appointment_date" label="Volgende gewenste toediening">
                                        <DatePicker onChange={(d) => setRenewAppointmentDate(d)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Item label={t('frequency')} name="frequency_times" rules={[{ required: true, pattern: '^[1-9]+[0-9]*$', message: 'Geef de freqentie in als getal' }]}>
                                        <Input addonAfter={t('timesPer')} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Item label={t('period')} name="frequency_period" rules={[{ required: true, message: 'Periode is een verplicht veld' }]}>
                                        <Select placeholder={t('pickAnOption')} addonBefore={"per"}>
                                            <Select.Option value={"Eenmalig"}>Eenmalig</Select.Option>
                                            <Select.Option value={"Dag"}>Dag</Select.Option>
                                            <Select.Option value={"Week"}>Week</Select.Option>
                                            <Select.Option value={"2-weken"}>2-weken</Select.Option>
                                            <Select.Option value={"3-weken"}>3-weken</Select.Option>
                                            <Select.Option value={"4-weken"}>4-weken</Select.Option>
                                            <Select.Option value={"5-weken"}>5-weken</Select.Option>
                                            <Select.Option value={"6-weken"}>6-weken</Select.Option>
                                            <Select.Option value={"8-weken"}>8-weken</Select.Option>
                                            <Select.Option value={"10-weken"}>10-weken</Select.Option>
                                            <Select.Option value={"12-weken"}>12-weken</Select.Option>
                                            <Select.Option value={"Maand"}>Maand</Select.Option>
                                            <Select.Option value={"2-maanden"}>2-maanden</Select.Option>
                                            <Select.Option value={"3-maanden"}>3-maanden</Select.Option>
                                            <Select.Option value={"6-maanden"}>6-maanden</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Item label="Hoeveelheid" name="frequency_amount" rules={[{ required: true, message: 'Hoeveelheid is een verplicht veld' }]}>
                                        <InputNumber style={{width: "100%"}} min={0} step={0.01} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Item label="Eenheid" name="frequency_unit" rules={[{ required: true, message: 'Eenheid is een verplicht veld' }]} initialValue={"mg"}>
                                        <Select style={{ width: "100%" }} disabled value={"mg"} defaultValue={"mg"}>
                                            <Select.Option value={"mcg"}>mcg</Select.Option>
                                            <Select.Option value={"mg"}>mg</Select.Option>
                                            <Select.Option value={"ml"}>ml</Select.Option>
                                            <Select.Option value={"gr"}>gr</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col>
                                    <strong>Het nieuwe totaal aantal handelingen wordt: {changeRecord?.aantal_handelingen + renewExtraAppointments}</strong>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col>
                                    <Form.Item label="Nieuwe leverdatum" name="renew_new_delivery_date">
                                        <DatePicker onChange={(d) => setRenewNewDeliveryDate(d)} format="DD-MM-YYYY" />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Nieuwe leverdatum tijdvak" name="renew_new_delivery_date_timeframe">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col>
                                    <br />
                                    <Form.Item label={t('frequencyInfo')} name="renew_frequency_info" rules={[{ required: false }]}>
                                        <TextArea rows={2} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col>
                                    <Button disabled={!renewAppointmentDate} onClick={() => updateUvv({
                                        action: "renew",
                                        id: changeRecord.re_id,
                                        re_id: changeRecord.re_id,
                                        uvv_id: changeRecord.id,
                                        bsn: changeRecord.bsn,
                                        extra_appointments: changeForm.getFieldValue('renew_extra_appointments'),
                                        num_appointments: (changeRecord.aantal_handelingen + renewExtraAppointments),
                                        appointment_date: renewAppointmentDate.format("YYYY-MM-DD"),
                                        frequency_times: changeForm.getFieldValue('frequency_times'),
                                        frequency_period: changeForm.getFieldValue('frequency_period'),
                                        frequency_amount: changeForm.getFieldValue('frequency_amount'),
                                        frequency_unit: changeForm.getFieldValue('frequency_unit'),
                                        frequency_info: changeForm.getFieldValue('renew_frequency_info'),
                                        leverdatum: renewNewDeliveryDate.format("YYYY-MM-DD"),
                                        leverdatum_tijdsvak: changeForm.getFieldValue('renew_new_delivery_date_timeframe'),
                                    })}>Vernieuwing opslaan</Button>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Stoppen / einddatum" key="einddatum">
                            <Divider>Ik wil het uitvoeringsverzoek stoppen of de einddatum van het recept wijzigen</Divider>
                            <Alert
                              style={{marginBottom: 20}}
                              description="Gebruik deze optie om het uitvoeringsverzoek stop te zetten of de einddatum van het uitvoeringsverzoek te wijzigen. Een einddatum in de toekomst is toegestaan."
                              type="info"
                              showIcon
                            />

                            <Row>
                                <Col>
                                    <Form.Item label="Einddatum recept" name="stop_date">
                                        <DatePicker onChange={(d) => setStopDate(d)} format="DD-MM-YYYY" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {changeRecord?.project_id === 4 && (
                              <Row gutter={16}>
                                  <Col>
                                      <Form.Item name="synagis_appointment_date" label="Volgende gewenste toediening">
                                          <DatePicker onChange={(d) => setSynagisAppointmentDate(d)} format="DD-MM-YYYY" />
                                      </Form.Item>
                                  </Col>
                                  <Col>
                                      <Form.Item label="Nieuwe leverdatum" name="new_delivery_date">
                                          <DatePicker onChange={(d) => setNewDeliveryDate(d)} format="DD-MM-YYYY" />
                                      </Form.Item>
                                  </Col>
                                  <Col>
                                      <Form.Item label="Nieuwe leverdatum tijdvak" name="new_delivery_date_timeframe">
                                          <Input />
                                      </Form.Item>
                                  </Col>
                              </Row>
                            )}
                            <Row>
                                <Col>
                                    <Button disabled={!stopDate || !synagisAppointmentDate} onClick={() => {
                                        if (changeRecord?.project_id === 4) {
                                            return updateUvv({
                                                action: "prolong",
                                                id: changeRecord.re_id,
                                                re_id: changeRecord.re_id,
                                                uvv_id: changeRecord.id,
                                                bsn: changeRecord.bsn,
                                                extra_appointments: 0,
                                                num_appointments: changeRecord.aantal_handelingen,
                                                appointment_date: synagisAppointmentDate.format("YYYY-MM-DD"),
                                                leverdatum: newDeliveryDate.format("YYYY-MM-DD"),
                                                leverdatum_tijdsvak: changeForm.getFieldValue('new_delivery_date_timeframe'),
                                                einddatum_recept: stopDate.format("YYYY-MM-DD"),
                                            })
                                        }
                                        else {
                                            return stopUvv();
                                        }
                                    }}>Einde zorg opslaan</Button>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </Modal>

            <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title={t("careRequests")}
                subTitle={t("overview")}
                /*extra={[
                    <Button key="1" type="primary" onClick={() => setShowForm(true)}>
                        <PlusCircleOutlined />
                        {t("newRequest")}
                    </Button>,
                ]}*/
            />

            <Form layout="vertical">
                <Row gutter={[16,8]} style={{paddingTop: 20}}>
                    <Col sm={6}>
                        <Form.Item label={"Zoeken"}>
                            <Input.Search
                                placeholder="Zoeken"
                                onSearch={value => setSearchQuery(value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col sm={6}>
                        <Form.Item label={"Medicatie"}>
                            <Select style={{width: "100%"}} value={selectedMedication} onChange={(value) => setSelectedMedication(value)}>
                                <Select.Option value="">Alle</Select.Option>
                                {medications.map((m, index) => {
                                    return <Select.Option value={m} key={index}>{m}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum vanaf"}>
                            <DatePicker value={dateFrom} onChange={(e) => setDateFrom(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum t/m"}>
                            <DatePicker value={dateUntil} onChange={(e) => setDateUntil(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Project"}>
                            <Select style={{ width: 200 }}>
                                <Select.Option>Alle projecten</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Button icon={<FileExcelOutlined/>} style={{marginRight: 10}} onClick={exportExcel} loading={downloading}>Export Excel</Button>
                </Col>
            </Row>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Table
                        scroll={{ x: 2500 }}
                        pagination={paginationActive}
                        onChange={handleChangeActive}
                        loading={loading}
                        expandedRowKeys={expandedRowKeys}
                        childrenColumnName={"appointments"}
                        onExpand={(expanded, record) => setExpandedRowKeys(expanded ? [record.key] : [])}
                        dataSource={careRequestsActive}
                        columns={columns} />
                </Col>
            </Row>
        </div>
    );
};

export default CareRequests;
